<template>        
    <canvas :id="key"/>
</template>

<script>
import { Chart, registerables, Tooltip } from 'chart.js'

export default {
    props:{
        data: {
            required: true
        },
        titulo: {
            required: false,
            default: false
        },
        campoValor: {
            required: false,
            default: 'valor'
        }
    },
    created(){
        this.key = Math.random(36).toString();
        Chart.register(...registerables);
    },
    mounted(){
        let vm = this;
        vm.Generate();
        vm.UpdateChart();
    },
    watch:{
        data: function (){
            this.UpdateChart();
        }
    },
    data: () => ({
        chart: {},
        head: "",
        headers: [],
        values: [],
        key: 'ada4sd8a9sd779654'
    }),
    methods:{
        Generate(){
            
            let vm = this;
            const canvas = document.getElementById(this.key);
            const config = {
                type: 'bar',
                options: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                title: function(context){
                                    var carinha = "";
                                    
                                    if(this.titulo)
                                        carinha = vm.headers[context[0].dataIndex].toDateTimeDDMMYYYY();
                                    
                                    return carinha;
                                },
                                label: function(context){
                                    return context.raw;
                                }
                            }
                        },
                        legend: {
                            display: false,
                            labels: {
                                color: this.$vuetify.theme.dark ? 'white' : 'black'
                            }
                        }
                    },
                    scales: {
                        y: {
                            ticks: {
                                color: this.$vuetify.theme.dark ? 'white' : 'lightgrey'
                            },
                            grid: {
                                drawBorder: false,
                                display: false,
                                color: this.$vuetify.theme.dark ? "DimGray" : 'lightgray'
                            }
                        },
                        x: {
                            ticks: {
                                color: this.$vuetify.theme.dark ? 'white' : 'black'
                            },
                            grid: {
                                drawBorder: false,
                                display: false,
                                color: this.$vuetify.theme.dark ? "DimGray" : 'lightgray'
                            }
                        }
                    }                 
                },                
            }

            this.chart = new Chart(canvas, config);
        },
        UpdateChart(){
            this.values = [];
            this.headers = [];
            this.data.forEach((x) => {
                this.values.push(x[this.campoValor]);
                this.headers.push(x.key);
            })

            if(this.chart){
                this.chart.data.datasets = [];
                this.chart.data.datasets = [
                    {
                        label: this.title,
                        data: this.values,
                        backgroundColor: [
                            'rgba(75, 192, 192, 0.3)',
                            'rgba(255, 99, 132, 0.3)',
                            'rgba(135, 206, 235, 0.3)',
                            'rgba(205, 133, 63, 0.3)',
                            'rgba(128, 128, 128, 0.3)',
                            'rgba(0, 139, 139, 0.3)',
                            'rgba(154, 205, 50, 0.3)',
                            'rgba(54, 162, 235, 0.3)',
                            'rgba(244, 164, 96, 0.3)',
                            'rgba(0, 128, 0, 0.3)',
                            'rgba(255, 206, 86, 0.3)',
                            'rgba(70, 130, 180, 0.3)',
                            'rgba(218, 165, 32, 0.3)',
                            'rgba(153, 102, 255, 0.3)'
                        ],
                        borderRadius: 4,
                        borderWidth: 1
                    }
                ];
                // this.chart.data.labels = this.headers;
                this.headers.forEach(x => {
                    this.chart.data.labels.push('');
                });             
                this.chart.update();
            }
        }
    }
}
</script>